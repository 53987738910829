<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">
        <protex-logo style="width: 150px !important; height: auto" />
      </b-link>
      <b-col lg="12" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="5" md="5" lg="5" class="px-xl-2 mx-auto">
          <b-card>
            <b-card-title class="mb-1">
              Reset Password for
              <u
                ><b>{{ userEmail }}</b></u
              >
            </b-card-title>
            <b-card-text class="mb-2">
              Your new password must be different from previously used passwords
            </b-card-text>

            <!-- #region form -->
            
            <validation-observer ref="simpleRules">
              <b-form
                autocomplete="off"
                method="POST"
                class="auth-reset-password-form mt-2"
                @submit.prevent="AwsPasswordReset"
              >
                <input
                  type="password"
                  style="position: fixed; top: -100px; left: -100px; width: 5px"
                />
                <b-form-group label="Code" label-for="verify-code">
                  <input
                    id="confirm-user"
                    name="confirm-user"
                    style="
                      position: fixed;
                      top: -100px;
                      left: -100px;
                      width: 5px;
                    "
                  />
                  <validation-provider
                    #default="{ errors }"
                    name="Code"
                    vid="code"
                    rules="required|digits:6"
                  >
                    <b-form-input
                      type="number"
                      id="confirm-user"
                      v-model="verification"
                      name="confirm-user"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="New Password"
                  label-for="reset-password-new"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="Password"
                    rules="required|password"
                  >
                    <b-input-group
                      :type="password1FieldType"
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="reset-password-new"
                        v-model="password"
                        :type="password2FieldType"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        name="reset-password-new"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="password1ToggleIcon"
                          @click="togglePassword1Visibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label-for="reset-password-confirm"
                  label="Confirm Password"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Confirm Password"
                    rules="required|confirmed:Password"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="reset-password-confirm"
                        v-model="cPassword"
                        :type="password2FieldType"
                        class="form-control-merge"
                        :state="errors.length > 0 ? false : null"
                        name="reset-password-confirm"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="password2ToggleIcon"
                          @click="togglePassword2Visibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-button block type="submit" variant="primary">
                  Set New Password
                </b-button>
              </b-form>
            </validation-observer>
            <!-- #endregion -->
            <p class="text-center mt-2">
              <b-link :to="{ name: 'auth-login' }">
                <feather-icon icon="ChevronLeftIcon" /> Back to login
              </b-link>
            </p>
          </b-card>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>

// #region imports
// General
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ProtexLogo from "@core/layouts/components/Logo.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { Auth } from "aws-amplify";

// Components
import {
  BCard,
  BCardTitle,
  BCardText,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BFormInput,
  BButton,
  BCol,
  BRow,
} from "bootstrap-vue";
// #endregion

export default {
  props: ["userEmail"],

  // #region data
  data() {
    return {
      verification: "",
      cPassword: "",
      password: "",
      required,
      password1FieldType: "password",
      password2FieldType: "password",
    };
  },
  // #endregion

  // #region components
  components: {
    ProtexLogo,
    BCard,
    BCol,
    BRow,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  // #endregion

  // #region computed
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    password2ToggleIcon() {
      return this.password2FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  // #endregion

  // #region methods
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType =
        this.password1FieldType === "password" ? "text" : "password";
    },
    togglePassword2Visibility() {
      this.password2FieldType =
        this.password2FieldType === "password" ? "text" : "password";
    },
    async AwsPasswordReset() {
      Auth.forgotPasswordSubmit(
        this.userEmail,
        this.verification,
        this.cPassword
      )
        .then((data) => {
          this.$toast.success({
            component: ToastificationContent,
            position: "top-center",
            props: {
              title: "Success",
              text: `Password Reset`,
            },
          });
          this.$router.replace({ name: "auth-login" });
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error({
            component: ToastificationContent,
            position: "top-center",
            props: {
              title: "Error",
              text: err.message,
            },
          });
        });
    },
  },
  // #endregion
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>

<style>
#userback_button_container {
  display: none !important;
}

#crisp-chatbox {
  visibility: hidden !important;
}

#crisp-chatbox > div > a > span.cc-7doi.cc-1ada > span > span.cc-15mo {
  visibility: hidden !important;
}
#crisp-chatbox > div > a > span > span > span{
  visibility: hidden !important;
}
</style>